import * as Sentry from '@sentry/react';
import AccountGuard from 'guards/AccountGuard';
import Background from 'components/Background';
import ConnectPage from 'pages/ConnectPage';
import HomePage from 'pages/HomePage';
import Layout from 'components/Layout';
import LocaleProvider from 'providers/LocaleProvider';
import React from 'react';
import WalletErrors from 'components/WalletErrors';
import { BrowserTracing } from '@sentry/tracing';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { InjectedConnector, WagmiProvider } from 'wagmi';
import { providers } from 'ethers';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import {
  chains,
  infuraId,
  quicknodeRpcUrl,
  locales,
  theme,
  sentryDsn,
  environment,
} from './config';

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.5,
    environment,
    maxValueLength: 1000,
  });
}

const connectors = () => {
  return [
    new InjectedConnector({
      chains,
      options: { shimDisconnect: true },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        infuraId,
        qrcode: true,
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: `Vesting | Fancy Bears Metaverse`,
      },
    }),
  ];
};

const provider = ({ chainId }: { chainId?: number }) => {
  if (chainId === 4) {
    return new providers.JsonRpcProvider(quicknodeRpcUrl);
  }

  const network = providers.InfuraProvider.getNetwork(chainId!);
  if (network.ensAddress) {
    return new providers.InfuraProvider(network, infuraId);
  }

  return providers.getDefaultProvider();
};

const extendedTheme = extendTheme(theme);

const App: React.FC = () => (
  <ChakraProvider theme={extendedTheme}>
    <WagmiProvider autoConnect provider={provider} connectors={connectors}>
      <LocaleProvider translations={locales.en}>
        <Background />

        <AccountGuard
          yeyElement={
            <Layout>
              <HomePage />
              <WalletErrors />
            </Layout>
          }
          neyElement={
            <Layout>
              <ConnectPage />
            </Layout>
          }
        />
      </LocaleProvider>
    </WagmiProvider>
  </ChakraProvider>
);

export default App;
