/* eslint-disable react-hooks/exhaustive-deps */
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import VestingContext, { VestingContextValue } from 'contexts/VestingContext';

import { BigNumber } from 'ethers';
import { honeyVestingContract } from 'config';
import { useConnect } from 'wagmi';
import useConnectedAccount from 'hooks/useConnectedAccount';
import useMulticall from 'hooks/useMulticall';
import useVestingEntities from 'hooks/useVestingEntities';
import useVestingSchedule from 'hooks/useVestingSchedule';

const VestingProvider: FunctionComponent = ({ children }) => {
  const [address] = useConnectedAccount();
  const [
    {
      data: { connected: isConnected },
    },
  ] = useConnect();
  const multicall = useMulticall();
  const [data, setData] = useState<VestingContextValue['data']>();
  const hasVestingEnded = useMemo(
    () =>
      data &&
      data.cliffEnd
        .add(data.numberOfRewardPeriods.mul(data.rewardPeriod))
        .lte(BigNumber.from(Math.floor(Date.now() / 1000))),
    [data]
  );

  const fetchVestingData = useCallback(async () => {
    if (!isConnected) {
      return;
    }

    try {
      const props = [
        'cliffStart',
        'cliffEnd',
        'rewardPeriod',
        'numberOfRewardPeriods',
      ];
      const results = await multicall(
        props.map(method => ({
          address: honeyVestingContract.address,
          data: honeyVestingContract.interface.encodeFunctionData(method, []),
        }))
      );

      const aggregatedResults: Record<string, any> = {};
      for (const [i, [, data]] of results.entries()) {
        const [decoded] = honeyVestingContract.interface.decodeFunctionResult(
          props[i],
          data
        );
        aggregatedResults[props[i]] = decoded;
      }

      setData(aggregatedResults as VestingContextValue['data']);
    } catch (err) {
      console.error(err);
    }
  }, [isConnected, multicall]);

  useEffect(() => {
    fetchVestingData();
  }, [fetchVestingData]);

  const [
    bearEntities,
    bearRemainingsTotal,
    bearReleasablesTotal,
    bearReleasedTotal,
    fetchBearEntities,
  ] = useVestingEntities(
    'getVestingAmountRemainingInBearsByWallet',
    'getBearStats',
    address
  );

  const [
    jarEntities,
    jarRemainingsTotal,
    jarReleasablesTotal,
    jarReleasedTotal,
    fetchJarEntities,
  ] = useVestingEntities(
    'getVestingAmountRemainingInJarsByWallet',
    'getJarStats',
    address
  );

  const remainingsTotal = useMemo(
    () =>
      bearRemainingsTotal &&
      jarRemainingsTotal &&
      bearRemainingsTotal.add(jarRemainingsTotal),
    [bearRemainingsTotal, jarRemainingsTotal]
  );

  const releasedTotal = useMemo(
    () =>
      bearReleasedTotal &&
      jarReleasedTotal &&
      bearReleasedTotal.add(jarReleasedTotal),
    [bearReleasedTotal, jarReleasedTotal]
  );

  const [scheduleForBear] = useVestingSchedule('vestingScheduleForBear');
  const [scheduleForJar] = useVestingSchedule('vestingScheduleForJar');

  const releasableTotal = useMemo(
    () =>
      bearReleasablesTotal &&
      jarReleasablesTotal &&
      bearReleasablesTotal.add(jarReleasablesTotal),
    [bearReleasablesTotal, jarReleasablesTotal]
  );

  const fetchVesting = useCallback(() => {
    fetchBearEntities();
    fetchJarEntities();
    fetchVestingData();
  }, [fetchBearEntities, fetchJarEntities, fetchVestingData]);

  const contextValue = useMemo<VestingContextValue>(
    () => ({
      data,
      hasVestingEnded,
      bearEntities,
      releasableBearEntities: bearEntities
        ?.filter(entity => !entity.releasable.isZero())
        .sort((entityA, entityB) =>
          entityA.tokenId.lt(entityB.tokenId) ? -1 : 1
        ),
      jarEntities,
      releasableJarEntities: jarEntities
        ?.filter(entity => !entity.releasable.isZero())
        .sort((entityA, entityB) =>
          entityA.tokenId.lt(entityB.tokenId) ? -1 : 1
        ),
      remainingsTotal,
      releasedTotal,
      releasableTotal,
      scheduleForBear,
      scheduleForJar,
      fetchVesting,
    }),
    [
      data,
      hasVestingEnded,
      bearEntities,
      jarEntities,
      remainingsTotal,
      releasedTotal,
      releasableTotal,
      scheduleForBear,
      scheduleForJar,
      fetchVesting,
    ]
  );

  return (
    <VestingContext.Provider value={contextValue}>
      {children}
    </VestingContext.Provider>
  );
};

export default VestingProvider;
