import { useEffect, useMemo, useState } from 'react';
import { honeyJarsContract } from 'config';
import { useContractRead } from 'wagmi';

const useHoneyJarsInWallet = (
  address?: string
): [tokenIds: number[] | undefined, readHoneyJarsInWallet: () => void] => {
  const [honeyJarsInWallet, setHoneyJarsInWallet] = useState<number[]>();

  const [{ data: honeyJarsInWalletData }, readHoneyJarsInWallet] =
    useContractRead(
      {
        addressOrName: honeyJarsContract.address,
        contractInterface: honeyJarsContract.interface,
      },
      'tokensInWallet',
      {
        args: address,
      }
    );

  useEffect(() => {
    if (honeyJarsInWalletData !== undefined) {
      setHoneyJarsInWallet(
        honeyJarsInWalletData.map(tokenId => tokenId.toNumber() as number)
      );
    }
  }, [honeyJarsInWalletData]);

  return useMemo(
    () => [honeyJarsInWallet, readHoneyJarsInWallet],
    [honeyJarsInWallet, readHoneyJarsInWallet]
  );
};

export default useHoneyJarsInWallet;
