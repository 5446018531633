import {
  Box,
  Center,
  HStack,
  Icon,
  IconProps,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import TinyHeading from './TinyHeading';

export type WalletAssetProps = StackProps & {
  title?: React.ReactNode;
  icon: React.FC;
  iconSize?: IconProps['w'];
  value?: string | number | JSX.Element;
  link?: { label: string; href: string };
  hint?: string | number | JSX.Element;
};

const WalletAsset: React.FC<WalletAssetProps> = ({
  title,
  icon,
  value,
  hint,
  link,
  iconSize = '7',
  children,
  ...rest
}) => (
  <HStack
    position="relative"
    w="full"
    bg="dark.800"
    borderRadius="lg"
    justify="space-between"
  >
    <VStack
      align="flex-start"
      px="3"
      py="3"
      gap="0"
      overflow="hidden"
      color="bright.50"
      spacing="1.5"
      {...rest}
    >
      {title && <TinyHeading>{title}</TinyHeading>}

      <HStack overflow="hidden" w="full">
        <Box>
          <Center w="7" h="7">
            <Icon as={icon} w={iconSize} h={iconSize} color="primary.500" />
          </Center>
        </Box>

        <VStack w="full" spacing="0" align="start">
          <Text fontSize="2xl" lineHeight="6" noOfLines={1} w="full">
            {value ?? '–'}
          </Text>

          {hint ? (
            <Text fontSize="xs" opacity="0.8">
              {hint}
            </Text>
          ) : null}
        </VStack>
      </HStack>
    </VStack>

    {link && (
      <Box
        as="a"
        href={link.href}
        target="_blank"
        position="absolute"
        top="0"
        right="0"
        fontSize="xs"
        fontWeight="semibold"
        borderRadius="md"
        borderTopLeftRadius="none"
        borderBottomRightRadius="none"
        px="3"
        py="1"
        bg="dark.800"
        transition="all 0.15s"
        _hover={{
          bg: 'dark.500',
        }}
      >
        {link.label}
      </Box>
    )}

    {children}
  </HStack>
);

export default WalletAsset;
