import React from 'react';
import {
  Button,
  Container,
  Flex,
  HStack,
  Icon,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import Logo from 'components/Logo';
import { getShortenedAddress } from 'utils/walletUtils';
import useConnectedAccount from 'hooks/useConnectedAccount';
import EthIcon from 'components/EthIcon';
import WalletPopup from 'components/WalletPopup';
import useAssets from 'hooks/useAssets';
import WalletAssetsContainer from 'components/WalletAssetsContainer';
import Panel from 'components/Panel';
import { FaWallet } from 'react-icons/fa';

const Header: React.FC = () => {
  const [address] = useConnectedAccount();
  const { formattedBalance } = useAssets();
  const [isWalletDialogVisible, setIsWalletDialogVisible] = useBoolean(false);

  return (
    <>
      <Panel as="header" px="0" py="4" borderRadius="0">
        <Container>
          <VStack align="stretch" spacing="6">
            <HStack justify="space-between" overflow="hidden">
              <Logo width="7em" height="auto" />

              {address && (
                <HStack spacing="6">
                  <Flex gap="0.5">
                    <Icon as={EthIcon} w="5" h="auto" color="primary.500" />
                    <Text>
                      {formattedBalance
                        ? formattedBalance.substring(0, 6)
                        : '–'}
                    </Text>
                  </Flex>

                  <Button
                    flex="none"
                    color="bright.50"
                    borderColor="dark.500"
                    _hover={{
                      borderColor: 'primary.500',
                    }}
                    colorScheme="bright"
                    borderRadius="full"
                    variant="outline"
                    size="sm"
                    leftIcon={<Icon as={FaWallet} color="primary.500" />}
                    onClick={setIsWalletDialogVisible.on}
                  >
                    {getShortenedAddress(address, 3)}
                  </Button>
                </HStack>
              )}
            </HStack>

            <WalletAssetsContainer />
          </VStack>
        </Container>
      </Panel>

      {address && (
        <WalletPopup
          isOpen={isWalletDialogVisible}
          onClose={setIsWalletDialogVisible.off}
        />
      )}
    </>
  );
};

export default Header;
