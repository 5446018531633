import { useMemo } from 'react';
import { useBalance } from 'wagmi';

const useFormattedBalance = (address?: string): string | undefined => {
  const [{ data: balanceData }] = useBalance({
    addressOrName: address,
  });

  return useMemo(() => balanceData?.formatted, [balanceData?.formatted]);
};

export default useFormattedBalance;
