import React, { useContext, useMemo } from 'react';
import VestingContext from 'contexts/VestingContext';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import { Line as LineChart } from 'react-chartjs-2';
import { useTheme } from '@chakra-ui/react';
import { hexToRGB } from 'utils/colorUtils';
import useTranslate from 'hooks/useTranslate';
import { formatEtherBalance } from 'utils/numberUtils';
import { BigNumber } from 'ethers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Annotation
);

const VestingChart = () => {
  const { data, remainingsTotal } = useContext(VestingContext);
  const { colors, fonts } = useTheme();
  const cliffStart = data!.cliffStart.toNumber() * 1000;
  const cliffEnd = data!.cliffEnd.toNumber() * 1000;
  const rewardPeriod = data!.rewardPeriod.toNumber() * 1000;
  const numberOfRewardPeriods = data!.numberOfRewardPeriods.toNumber();
  const numberOfCliffPeriods = Math.floor(
    (cliffEnd - cliffStart) / rewardPeriod
  );
  const translate = useTranslate();

  // Filter from the end (minium 2 periods and take the previous one so that chart isn't clipped)
  const x = useMemo(() => {
    const periods: number[] = [];

    for (
      let i = numberOfCliffPeriods + numberOfRewardPeriods - 1;
      i >= 0;
      i--
    ) {
      const period = cliffStart + i * rewardPeriod;
      periods.unshift(period);

      // if (periods.length >= 2 && period <= Date.now()) {
      //   break;
      // }
    }

    return periods;
  }, [cliffStart, rewardPeriod, numberOfCliffPeriods, numberOfRewardPeriods]);

  const y = x.map(
    period =>
      Math.max(period - cliffEnd + rewardPeriod, 0) /
      rewardPeriod /
      numberOfRewardPeriods
  );

  const currentPeriodIndex =
    x.length -
    x
      .slice()
      .reverse()
      .findIndex(period => {
        return Date.now() >= period;
      }) -
    1;
  const annotationValue = x[currentPeriodIndex + 1]
    ? currentPeriodIndex +
      (x[currentPeriodIndex + 1] - Date.now()) / rewardPeriod
    : currentPeriodIndex;

  return (
    <LineChart
      options={{
        layout: {
          padding: {
            top: 10,
          },
        },
        responsive: true,
        animation: {
          duration: 0,
        },
        interaction: {
          intersect: false,
          axis: 'x',
        },
        plugins: {
          tooltip: {
            backgroundColor: hexToRGB(colors.dark[400], 0.9),
            displayColors: false,
            caretPadding: 8,
            padding: 8,
            titleColor: colors.bright['500'],
            titleFont: {
              family: fonts.heading,
            },
            bodyColor: colors.bright['500'],
            bodyFont: {
              family: fonts.body,
            },
            footerColor: colors.bright['500'],
            footerFont: {
              family: fonts.body,
              weight: 'normal',
            },
            cornerRadius: 0,
            callbacks: {
              title: ([{ dataIndex }]) => {
                const fraction = 10 ** 6;
                const divider = BigNumber.from(
                  Math.floor(y[dataIndex] * fraction)
                );

                return remainingsTotal
                  ? `${
                      divider.isZero()
                        ? '0'
                        : formatEtherBalance(
                            remainingsTotal.mul(divider).div(fraction),
                            3
                          ).toString()
                    } $HONEY`
                  : '-';
              },
              label: ({ dataIndex }) => `${(y[dataIndex] * 100).toFixed(2)}%`,
              footer: ([{ dataIndex }]) =>
                new Date(x[dataIndex]).toLocaleDateString(),
            },
          },
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              nowLine: {
                type: 'line',
                value: currentPeriodIndex,
                scaleID: 'x',
                borderColor: hexToRGB(colors.primary['500'], 0.8),
                borderWidth: 2,
              },
              nowLabel: {
                type: 'label',
                xValue: currentPeriodIndex,
                yValue: 0.94,
                backgroundColor: hexToRGB(colors.primary['500'], 1),
                color: hexToRGB(colors.bright['500']),
                content: translate('chart:now'),
                font: {
                  size: 10,
                  weight: '600',
                },
                xAdjust:
                  -((annotationValue - currentPeriodIndex) / (x.length - 1)) *
                    36 +
                  18,
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
            min: currentPeriodIndex,
          },
          y: {
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              display: false,
            },
            min: y[0] - (y[1] - y[0]),
          },
        },
      }}
      data={{
        labels: x,
        datasets: [
          {
            data: x.map((period, i) => ({
              x: period,
              y: y[i],
            })),
            pointRadius: 6,
            pointHoverRadius: 6,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: colors.secondary['500'],
            pointHoverBorderColor: colors.secondary['500'],
            borderColor: colors.secondary['500'],
            borderWidth: 2,
            fill: 'start',
            backgroundColor: ({ chart }) => {
              const gradient = chart.ctx.createLinearGradient(
                0,
                0,
                0,
                chart.chartArea?.height || chart.height
              );
              gradient.addColorStop(0, `${colors.secondary['500']}20`);
              gradient.addColorStop(1, `${colors.dark['500']}B0`);

              return gradient;
            },
            stepped: true,
          },
        ],
      }}
    />
  );
};

export default VestingChart;
