import React from 'react';

const FanadiseLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="105"
    height="auto"
    viewBox="0 0 105 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor">
      <path d="M35.829 13.0686v7.325h-2.1721v-7.325H32.501v-1.8169h1.1559v-.4292c0-2.73733 1.8157-3.1093 3.458-3.1093v1.90754c-.6742 0-1.2859.15737-1.2859 1.38296v.2718h1.1077v1.817l-1.1077-.0239Z" />
      <path d="M43.063 19.6353c-.3533.3566-.7765.6379-1.2437.8265-.4671.1886-.9683.2806-1.4727.2704-.4032.0105-.8044-.0605-1.1789-.2089-.3745-.1484-.7144-.371-.999-.654-.2845-.2831-.5077-.6208-.6557-.9923a2.9069 2.9069 0 0 1-.2054-1.1683c0-1.6739 1.2859-3.0234 3.3714-3.0234.7786-.0231 1.546.1881 2.201.6056v-.4244c0-1.5928-.6406-2.0697-1.9602-2.0554-.7688.0228-1.5334.1202-2.2829.2909l-.0674-1.8169a11.28891 11.28891 0 0 1 2.6055-.2862c2.6538 0 3.853 1.2256 3.853 3.2142v6.1995h-1.965v-.7773Zm-.1734-1.9886a1.57366 1.57366 0 0 0-.1141-.6395c-.0821-.2031-.2057-.3872-.3631-.5408-.1573-.1536-.3451-.2733-.5514-.3517a1.61668 1.61668 0 0 0-.6474-.1037 1.60008 1.60008 0 0 0-.633.1077 1.5876 1.5876 0 0 0-.5378.3477c-.1534.151-.2739.3315-.3542.5304-.0803.1989-.1186.412-.1126.6261-.0151.2222.016.4451.0914.6549.0754.2098.1935.402.347.5648.1536.1627.3392.2925.5455.3813.2063.0888.4288.1347.6537.1348.2263-.0011.4501-.0473.658-.1359.2079-.0886.3957-.2177.5521-.3797.1564-.162.2783-.3536.3584-.5632.08-.2096.1166-.4331.1075-.657v.0238Z" />
      <path d="M48.6306 20.3936h-2.1721v-9.1419h2.0758v1.0062c.3902-.4024.8594-.7216 1.3786-.9381.5193-.2165 1.0778-.3257 1.6412-.3209 1.965 0 3.3136 1.2876 3.3136 3.3907v6.004h-2.1722v-5.3507c0-1.5928-.9295-2.1507-2.0083-2.1507-.5359-.0039-1.0518.2009-1.4365.5702-.3848.3694-.6075.8736-.6201 1.4041v5.5271Z" />
      <path d="M61.5913 19.6353c-.3533.3566-.7765.6378-1.2436.8265a3.7333 3.7333 0 0 1-1.4727.2704c-.4033.0105-.8044-.0605-1.1789-.2089-.3745-.1484-.7145-.371-.999-.654-.2846-.2831-.5077-.6208-.6558-.9923a2.9069 2.9069 0 0 1-.2054-1.1683c0-1.6739 1.286-3.0234 3.3714-3.0234.7786-.0231 1.546.188 2.201.6056v-.4244c0-1.5928-.6405-2.0697-1.9602-2.0554a11.8173 11.8173 0 0 0-2.2829.2909l-.0578-1.8169a11.2673 11.2673 0 0 1 2.6056-.2862c2.6537 0 3.853 1.2256 3.853 3.2142v6.1995h-1.9795l.0048-.7773Zm-.1782-1.9886a1.56891 1.56891 0 0 0-.1196-.6318 1.58293 1.58293 0 0 0-.3634-.5326 1.6027 1.6027 0 0 0-.5472-.3457 1.6153 1.6153 0 0 0-.641-.1018 1.60017 1.60017 0 0 0-.633.1078 1.58595 1.58595 0 0 0-.5377.3476c-.1535.151-.274.3315-.3543.5304-.0803.1989-.1186.412-.1125.6261-.0152.2222.0159.4451.0913.6549.0754.2098.1935.4021.3471.5648.1535.1628.3391.2926.5454.3814.2063.0887.4288.1346.6537.1348.2283-.001.4539-.0479.6633-.138.2093-.0901.3979-.2215.5543-.3862a1.6612 1.6612 0 0 0 .3553-.5715c.0779-.2125.1114-.4384.0983-.664v.0238Z" />
      <path d="M73.0443 19.1584c-.4756.5015-1.0525.8982-1.6931 1.1644-.6405.2662-1.3305.3959-2.0251.3807-.6314-.0054-1.2555-.1357-1.8353-.3832-.5799-.2476-1.104-.6074-1.5415-1.0584-.4374-.4509-.7795-.9838-1.006-1.5675-.2265-.5837-.3329-1.2063-.313-1.8312 0-2.6897 2.0276-4.9024 4.6958-4.9024.6688-.0065 1.3321.1197 1.9509.3711.6187.2515 1.1803.6231 1.6517 1.0929V7.97548h2.1721V20.3745h-2.0565v-1.2161Zm-3.121-6.3187c-.7806.0309-1.5189.3596-2.0603.9175-.5413.5578-.8436 1.3014-.8436 2.075 0 .7736.3023 1.5171.8436 2.075.5414.5578 1.2797.8865 2.0603.9174.4071.0161.8132-.0494 1.194-.1925.3809-.1431.7286-.361 1.0223-.6404.2938-.2795.5274-.6148.6871-.9859.1596-.3711.2419-.7703.2419-1.1736 0-.4034-.0823-.8026-.2419-1.1737a2.99429 2.99429 0 0 0-.6871-.9858c-.2937-.2795-.6414-.4973-1.0223-.6405a3.05191 3.05191 0 0 0-1.194-.1925Z" />
      <path d="M78.7513 9.96409h-2.2347V7.97548h2.2347v1.98861Zm-.0289 10.42951h-2.1721v-9.1419h2.1721v9.1419Z" />
      <path d="M85.2485 11.3328v1.9075c-.5855-.3097-1.2343-.4842-1.8976-.5103-.7369 0-1.1751.2862-1.1751.7488 0 .3147.3901.6533 1.2907 1.2256 2.201 1.402 2.7453 2.2938 2.7453 3.3381 0 1.5785-1.2667 2.6468-3.3714 2.6611-.9418-.0443-1.8674-.2592-2.7308-.6343v-1.9075c.7432.4029 1.5636.6466 2.4081.7153.9633 0 1.3967-.3958 1.3967-.8584 0-.3052-.2552-.6533-1.7675-1.6071-1.8976-1.2256-2.2685-1.9075-2.2685-3.0282s.9633-2.4512 3.2799-2.4321c.7151-.0084 1.4251.1212 2.0902.3815Z" />
      <path d="M92.3431 20.8704c-3.3136 0-5.452-2.165-5.452-5.0502-.0208-.662.093-1.3214.3345-1.9391.2415-.6177.6059-1.181 1.0715-1.6566.4656-.4755 1.0229-.8536 1.639-1.1119.616-.2582 1.2781-.3912 1.947-.3912s1.3311.133 1.9471.3912c.616.2583 1.1733.6364 1.6389 1.1119.4656.4756.83 1.0389 1.0715 1.6566a4.9 4.9 0 0 1 .3345 1.9391v.5437h-7.6674c.1926 1.6405 1.2522 2.661 3.1354 2.661.5775.0232 1.1536-.071 1.6929-.2769.5393-.2059 1.0302-.519 1.4424-.9201l1.3004 1.3544c-.5885.5597-1.2841.9974-2.0458 1.2873-.7617.2899-1.5743.4262-2.3899.4008Zm-3.0391-6.1613h5.1582c-.1187-.5908-.4407-1.1225-.9109-1.5047-.4703-.3821-1.0598-.5909-1.6682-.5909-.6083 0-1.1979.2088-1.6681.5909-.4703.3822-.7922.9139-.911 1.5047Z" />
      <path d="M21.5779 25.6251c-8.1876 0-13.48547-4.7355-14.01044-9.5663-.45273-4.3826 1.44487-7.11517 3.08244-8.63643 2.6296-2.43689 6.6897-3.52418 10.5475-2.84701 3.9975.70579 4.8885 2.58472 4.9367 4.03922.0057.68703-.1337 1.36765-.4094 1.99812.3738.1499.7306.3386 1.0644.5628.4303.2653.7861.6339 1.0344 1.0715.2483.4376.3812.9302.3864 1.4321 0 1.2494-1.0452 2.2366-2.254 3.3811-1.9265 1.836-4.1324 3.9105-2.5912 7.5539l.3901.9251-1.0065.0525c-.3998.0191-.7995.0334-1.1704.0334ZM18.9001 5.80573c-2.6754-.04548-5.2718.8982-7.2822 2.64671-2.03725 1.86936-2.94752 4.44936-2.63447 7.45846.54424 5.2458 6.83907 8.274 12.71967 8.2978-1.1992-3.9629 1.3678-6.3712 3.2606-8.1643.9344-.8822 1.8109-1.7167 1.8109-2.3844-.0061-.2743-.0833-.5425-.2241-.7788-.1409-.2363-.3406-.4328-.5802-.5708a4.43992 4.43992 0 0 0-.9054-.4768c-1.5798 2.4893-4.5755 4.7211-6.6657 5.2457-2.3841.5818-3.9301-.6533-4.3346-2.0411-.4817-1.5308.2504-3.6243 2.9523-4.6067 1.6472-.59133 4.8163-.81069 7.34-.2432.231-.47474.3527-.99432.3564-1.52126-.0434-1.36389-1.3197-2.26998-3.7663-2.70394a11.87578 11.87578 0 0 0-2.0469-.15737Zm2.2154 5.49367c-1.23-.0347-2.4577.1263-3.6362.4769-1.8302.6629-2.36 1.9505-2.0758 2.8613.2456.8012 1.1655 1.4307 2.6585 1.0826 1.6472-.4006 4.1372-2.2557 5.558-4.2443a14.34288 14.34288 0 0 0-2.5045-.2003v.0238Z" />
    </g>
  </svg>
);

export default FanadiseLogo;
