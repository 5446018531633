import React, { Fragment } from 'react';
import DataList, { DataListProps } from 'components/DataList';
import { BigNumber } from 'ethers';
import HoneyAmount from 'components/HoneyAmount';

export type HoneyDataListProps = DataListProps & {
  items: {
    label: string;
    honey?: BigNumber;
  }[];
};

const HoneyDataList: React.FC<HoneyDataListProps> = ({ items, ...rest }) => (
  <DataList {...rest}>
    {items.map(({ label, honey }, i) => {
      const isZero = !honey || honey.isZero();

      return honey ? (
        <Fragment key={i}>
          <DataList.Term>{label}</DataList.Term>

          <DataList.Value
            color={isZero ? 'bright.500' : 'secondary.500'}
            fontWeight={isZero ? 'normal' : 'semibold'}
            opacity={isZero ? 0.8 : 1}
          >
            <HoneyAmount textAlign="right" justify="end" amount={honey} />
          </DataList.Value>
        </Fragment>
      ) : null;
    })}
  </DataList>
);

export default HoneyDataList;
