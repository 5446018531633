import { hexToRGB } from 'utils/colorUtils';
import { SystemStyleFunction } from '@chakra-ui/theme-tools';

type StyleProps = Parameters<SystemStyleFunction>[0];

const theme = {
  styles: {
    global: {
      body: {
        overflowX: 'hidden',
        backgroundColor: 'dark.500',
        fontWeight: '500',
        color: 'bright.50',
      },
      'html, body, #root': {
        height: 'full',
        fontSize: {
          base: '14px',
          sm: '16px',
        },
      },
      '*:focus': {
        boxShadow: 'none !important',
      },
      '*': {
        webkitTapHighlightColor: 'rgba(255, 255, 255, 0)',
      },
    },
  },
  fonts: {
    heading: 'Montserrat',
    body: 'Montserrat',
  },
  colors: {
    primary: {
      50: '#f9d7a5',
      100: '#f7cf92',
      200: '#f6c780',
      300: '#f5bf6e',
      400: '#f3b75c',
      500: '#f2af4a',
      600: '#da9e43',
      700: '#c28c3b',
      800: '#a97a34',
      900: '#91692c',
    },
    secondary: {
      50: '#f9d7a5',
      100: '#f7cf92',
      200: '#f6c780',
      300: '#f5bf6e',
      400: '#f3b75c',
      500: '#f2af4a',
      600: '#da9e43',
      700: '#c28c3b',
      800: '#a97a34',
      900: '#91692c',
    },
    dark: {
      50: '#DBDADC',
      100: '#B7B5B9',
      200: '#726F78',
      300: '#929097',
      400: '#6E6B74',
      500: '#4A4651',
      600: '#36313E',
      700: '#2D2834',
      800: '#24202A',
      900: '#18151D',
    },
    bright: {
      50: '#fffcfa',
      100: '#fff9f4',
      200: '#fff6ef',
      300: '#fff3e9',
      400: '#fff0e4',
      500: '#ffebdb',
      600: '#ffe7d4',
      700: '#ffdfc6',
      800: '#ffdbbf',
      900: '#ffd7b8',
    },
  },
  radii: {
    sm: '8px',
    md: '10px',
    lg: '12px',
  },
  components: {
    Container: {
      baseStyle: {
        maxW: 'lg',
        px: '4',
      },
    },
    Progress: {
      baseStyle: {
        track: {
          bg: hexToRGB('#FFFFFF', 0.25),
          borderRadius: 'full',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 'lg',
        textDecoration: 'none',
        boxSizing: 'bordered-box',
        borderWidth: '0',
      },
      variants: {
        solid: (props: StyleProps) => {
          if (props.colorScheme === 'dark') {
            return { color: 'bright.50' };
          }

          return { color: 'dark.900' };
        },
        outline: {
          border: '2px solid',
          color: 'bright.50',
          borderColor: 'dark.500',
          _active: {
            color: 'primary.500',
            bg: 'transparent',
            borderColor: 'primary.500',
          },
          _hover: {
            color: 'primary.500',
            bg: 'transparent',
            borderColor: 'primary.500',
          },
          _disabled: {
            color: 'dark.50',
            borderColor: 'dark.500',
            pointerEvents: 'none',
          },
        },
        ghost: {
          _hover: {
            bg: 'dark.800',
          },
          _active: {
            bg: 'dark.800',
          },
        },
        link: {
          color: 'bright.50',
          _active: {
            color: 'primary.500',
          },
          _hover: {
            color: 'primary.500',
            textDecoration: 'none',
          },
          _disabled: {
            color: 'bright.50',
            pointerEvents: 'none',
          },
        },
      },
      sizes: {
        xs: {
          px: 3,
          height: 8,
          minW: 8,
        },
        sm: {
          px: 4,
          height: 10,
          minW: 10,
        },
        md: {
          px: 8,
          height: 12,
          minW: 12,
        },
        lg: {
          h: 14,
          px: 6,
          minW: 12,
          fontSize: 'md',
        },
      },
      defaultProps: {
        colorScheme: 'primary',
      },
    },
    CloseButton: {
      baseStyle: {
        borderRadius: 'full',
        _hover: {
          bg: 'dark.600',
        },
      },
    },
    Badge: {
      baseStyle: {
        fontWeight: 'semibold',
        borderRadius: 'full',
      },
    },
    Input: {
      sizes: {
        sm: {
          field: {
            borderRadius: 'lg',
          },
        },
        md: {
          field: {
            borderRadius: 'lg',
          },
        },
        lg: {
          field: {
            px: 4,
            borderRadius: 'lg',
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: 'dark.500',
            border: '2px solid',
            _placeholder: {
              color: 'dark.100',
              opacity: 0.5,
              fontFamily: 'inherit',
            },
            _hover: {
              borderColor: 'dark.500',
            },
            _focus: {
              borderColor: 'primary.500',
              boxShadow: 'none',
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'primary.500',
        errorBorderColor: 'primary.500',
      },
    },
    InputRightAddon: {
      baseStyle: {
        bg: 'transparent',
      },
    },
    Link: {
      baseStyle: {
        color: 'dark',
        _hover: {
          color: 'primary.500',
          textDecoration: 'none',
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          boxShadow: 'none',
          bg: 'transparent',
          borderRadius: 'none',
        },
        body: {
          py: 6,
          px: {
            base: 4,
            sm: 6,
          },
          borderRadius: 'lg',
          bg: 'dark.900',
          color: 'bright.50',
        },
        closeButton: {
          top: 5,
          right: 5,
        },
        overlay: {
          bg: hexToRGB('#1b1b1b', 0.75),
        },
      },
      defaultProps: {
        isCentered: true,
      },
    },
    Divider: {
      baseStyle: {
        borderColor: 'dark.500',
        borderBottomWidth: '2px',
      },
    },
    Tooltip: {
      baseStyle: {
        bg: 'dark.500',
        color: 'bright.50',
        borderRadius: 'md',
      },
    },
    Tabs: {
      baseStyle: {
        tabpanel: {
          p: '0',
          pt: '6',
          pb: '0',
        },
      },
      variants: {
        line: {
          tablist: {
            borderColor: 'dark.600',
            fontWeight: 'semibold',
          },
          tab: {
            py: 3,
            borderColor: 'transparent',
            fontWeight: 'semibold',
            borderBottom: '2px solid',
            _active: {
              bg: 'transparent',
            },
          },
        },
      },
      defaultProps: {
        colorScheme: 'secondary',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'sm',
        fontWeight: 'semibold',
        mb: '1',
        ml: '1.5',
      },
    },
  },
};

export default theme;
