import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { formatEther } from 'ethers/lib/utils';

export const formatEtherBalance = (value: BigNumberish, decimals = 4) => {
  const result = BigNumber.isBigNumber(value)
    ? Number(formatEther(value))
    : Number(value);

  return parseFloat(result.toFixed(decimals));
};
