import { BigNumberish } from 'ethers';
import { formatEtherBalance } from 'utils/numberUtils';

type EtherAmountProps = {
  amount?: BigNumberish;
};

const EtherAmount = ({ amount }: EtherAmountProps) => (
  <>{amount ? formatEtherBalance(amount) : '–'}</>
);

export default EtherAmount;
