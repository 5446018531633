import { HStack } from '@chakra-ui/react';
import HoneyIcon from 'components/HoneyIcon';
import React from 'react';
import WalletAsset from 'components/WalletAsset';
import { formatEtherBalance } from 'utils/numberUtils';
import { uniswapUrl } from 'config';
import useAssets from 'hooks/useAssets';
import useTranslate from 'hooks/useTranslate';

const WalletAssetsContainer: React.FC = () => {
  const translate = useTranslate();
  const { honeyBalance } = useAssets();

  return (
    <HStack justify="stretch" spacing="3">
      <WalletAsset
        title={translate('header:honey:title')}
        icon={HoneyIcon}
        value={honeyBalance && formatEtherBalance(honeyBalance)}
        link={
          uniswapUrl
            ? {
                href: uniswapUrl,
                label: translate('common:buy'),
              }
            : undefined
        }
      />
    </HStack>
  );
};

export default WalletAssetsContainer;
