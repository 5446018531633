import React, { useContext, useEffect } from 'react';
import useTranslate from 'hooks/useTranslate';
import VestingContext from 'contexts/VestingContext';
import WalletAsset from 'components/WalletAsset';
import FancyBearIcon from 'components/FancyBearIcon';
import JarIcon from 'components/JarIcon';
import useAssets from 'hooks/useAssets';
import { Box, Button, SimpleGrid, useDisclosure } from '@chakra-ui/react';
import HoneyIcon from 'components/HoneyIcon';
import ReleaseHoneyPopup from 'components/ReleaseHoneyPopup';
import VesitngDetailsPopup from 'components/VestingDetailsPopup';
import { formatEtherBalance } from 'utils/numberUtils';

const VestingBalance: React.FC = () => {
  const translate = useTranslate();
  const { remainingsTotal, releasedTotal, releasableTotal } =
    useContext(VestingContext);
  const { fancyBearsInWalletCount, honeyJarsInWalletCount } = useAssets();
  const isClaimDisabled = !releasableTotal || releasableTotal.isZero();

  const {
    isOpen: isClaimModalOpen,
    onOpen: onClaimModalOpen,
    onClose: onClaimModalClose,
  } = useDisclosure();

  const {
    isOpen: isDetailsModalOpen,
    onOpen: onDetailsModalOpen,
    onClose: onDetailsModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (isClaimModalOpen && isClaimDisabled) {
      onClaimModalClose();
    }
  }, [isClaimDisabled, isClaimModalOpen, onClaimModalClose]);

  return (
    <>
      <SimpleGrid columns={2} gap="1.5" mb="1.5">
        <WalletAsset
          title={translate('vesting:balance:bears')}
          icon={FancyBearIcon}
          value={fancyBearsInWalletCount}
        />

        <WalletAsset
          title={translate('vesting:balance:jars')}
          icon={JarIcon}
          value={honeyJarsInWalletCount}
        />
      </SimpleGrid>

      <SimpleGrid columns={1} gap="1.5">
        <WalletAsset
          title={translate('vesting:balance:vested')}
          icon={HoneyIcon}
          value={
            remainingsTotal ? formatEtherBalance(remainingsTotal) : undefined
          }
        />

        <WalletAsset
          title={translate('vesting:balance:released')}
          icon={HoneyIcon}
          value={releasedTotal ? formatEtherBalance(releasedTotal) : undefined}
        />

        <WalletAsset
          title={translate('vesting:balance:releasable')}
          icon={HoneyIcon}
          value={
            releasableTotal ? formatEtherBalance(releasableTotal) : undefined
          }
        >
          <Box px="3">
            <Button
              onClick={() => onClaimModalOpen()}
              isDisabled={isClaimDisabled}
              colorScheme="dark"
              size="sm"
              flexShrink="0"
              px="3"
            >
              {translate('vesting:balance:release')}
            </Button>
          </Box>
        </WalletAsset>

        <Box px="1.5" pb="1.5" pt="3">
          <Button onClick={() => onDetailsModalOpen()} w="full">
            {translate('vesting:balance:viewDetails')}
          </Button>
        </Box>
      </SimpleGrid>

      <ReleaseHoneyPopup
        isOpen={isClaimModalOpen && !isClaimDisabled}
        onClose={onClaimModalClose}
      />

      <VesitngDetailsPopup
        isOpen={isDetailsModalOpen}
        onClose={onDetailsModalClose}
      />
    </>
  );
};

export default VestingBalance;
