import React from 'react';
import useTranslate from 'hooks/useTranslate';
import ReleaseHoneyContainer from 'components/ReleaseHoneyContainer';
import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
  Text,
  VStack,
} from '@chakra-ui/react';

type ReleaseHoneyPopupProps = Omit<ModalProps, 'children'> & {};

const ReleaseHoneyPopup: React.FC<ReleaseHoneyPopupProps> = ({
  onClose,
  ...rest
}) => {
  const translate = useTranslate();

  return (
    <Modal onClose={onClose} {...rest}>
      <ModalOverlay />

      <ModalContent w="sm" maxH="calc(100vh - 2rem)" mt="1rem" mb="1rem">
        <ModalCloseButton />
        <ModalBody
          py="6"
          px="0"
          display="flex"
          flexDir="column"
          maxH="full"
          overflow="auto"
        >
          <VStack
            pt="4"
            px="10"
            w="full"
            spacing="2"
            align="stretch"
            textAlign="center"
          >
            <Heading fontSize="2xl" fontWeight="bold">
              {translate('vesting:release:title')}
            </Heading>

            <Text fontSize="sm" opacity="0.8">
              {translate('vesting:release:description')}
            </Text>
          </VStack>

          <ReleaseHoneyContainer onClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReleaseHoneyPopup;
