import React from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import { filestoreUrl, publicUrl } from 'config';
import { VestingEntity } from 'hooks/useVestingEntities';
import HoneyDataList from 'components/HoneyDataList';
import useTranslate from 'hooks/useTranslate';

export interface VestingDetailsCardProps {
  type: 'bear' | 'jar';
  stats: VestingEntity;
}

const VestingDetailsCard: React.FC<VestingDetailsCardProps> = ({
  type,
  stats: { tokenId, ...stats },
}) => {
  const translate = useTranslate();

  return (
    <AccordionItem
      role="button"
      userSelect="none"
      tabIndex={0}
      onClick={() => {}}
      position="relative"
      bg="dark.800"
      borderRadius="md"
      w="full"
      transition="all 0.15s"
      textAlign="left"
      border="none"
    >
      <AccordionButton p="1.5">
        <HStack spacing="3" w="full">
          <Box w="10" h="10" flexShrink="0">
            <Image
              src={
                type === 'bear'
                  ? `${filestoreUrl}/256/${tokenId}.png`
                  : `${publicUrl}/jar.png`
              }
              fallbackSrc={
                type === 'bear'
                  ? `${publicUrl}/bear-placeholder.png`
                  : `${publicUrl}/jar-placeholder.png`
              }
              fit="cover"
              borderRadius="sm"
            />
          </Box>

          <Text textAlign="left" flexGrow="1" fontWeight="semibold">
            #{tokenId.toString()}
          </Text>

          <Box pr="1.5">
            <AccordionIcon w="6" h="auto" />
          </Box>
        </HStack>
      </AccordionButton>

      <AccordionPanel p="3" bg="dark.600" borderBottomRadius="md">
        <HoneyDataList
          fontSize="sm"
          items={['remaining', 'releasable', 'released', 'spent'].map(key => ({
            label: translate(`token:stats:${key}`),
            honey: stats[key as keyof typeof stats],
          }))}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default VestingDetailsCard;
