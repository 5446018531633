import React from 'react';
import { BigNumber } from 'ethers';
import { Box, Center, HStack, Icon, Image, Text } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { filestoreUrl, publicUrl } from 'config';
import HoneyAmount from 'components/HoneyAmount';

export interface ReleaseHoneyCardProps {
  id: number;
  honey: BigNumber;
  type: 'bear' | 'jar';
  isSelected: boolean;
  isDisabled: boolean;
  onClick: (id: number) => void;
  divideFraction?: number;
}

const ReleaseHoneyCard: React.FC<ReleaseHoneyCardProps> = ({
  id,
  honey,
  type,
  isSelected,
  isDisabled,
  onClick,
}) => (
  <Box
    role="button"
    userSelect="none"
    tabIndex={0}
    onClick={() => {
      if (!isDisabled && onClick) {
        onClick(id);
      }
    }}
    position="relative"
    bg={isSelected ? 'dark.700' : 'dark.800'}
    color="bright.500"
    p="1.5"
    pr="3"
    w="full"
    cursor={isDisabled ? 'not-allowed' : 'pointer'}
    opacity={isDisabled ? '0.8' : undefined}
    transition="all 0.15s"
    textAlign="left"
    borderRadius="sm"
  >
    <Center
      position="absolute"
      top="0"
      left="0"
      bg="dark.700"
      w="6"
      h="6"
      zIndex="base"
      opacity={isSelected ? '1' : '0'}
      transition="opacity 0.15s"
      borderRadius="sm"
    >
      <Icon as={FaCheck} w="3" h="auto" color="primary.500" />
    </Center>

    <HStack spacing="4">
      <Box
        w="10"
        h="10"
        bg="dark.500"
        flexShrink="0"
        borderRadius="sm"
        overflow="hidden"
      >
        <Image
          src={
            type === 'bear'
              ? `${filestoreUrl}/256/${id}.png`
              : `${publicUrl}/jar.png`
          }
          fallbackSrc={
            type === 'bear' ? `${publicUrl}/bear-placeholder.png` : undefined
          }
          fit="cover"
        />
      </Box>

      <Text flexGrow="1">#{id}</Text>

      <HoneyAmount
        amount={honey}
        color="secondary.500"
        fontWeight="semibold"
        fontSize="sm"
      />
    </HStack>
  </Box>
);

export default ReleaseHoneyCard;
