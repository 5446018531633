import { Button, Box, ModalProps, VStack } from '@chakra-ui/react';
import useConnectedAccount from 'hooks/useConnectedAccount';
import CopyInput from '../components/CopyInput';
import EtherAmount from '../components/EtherAmount';
import SimplePopup from '../components/SimplePopup';
import TinyHeading from '../components/TinyHeading';
import WalletAsset from '../components/WalletAsset';
import useTranslate from '../hooks/useTranslate';
import EthIcon from './EthIcon';
import HoneyIcon from './HoneyIcon';
import useAssets from 'hooks/useAssets';

type WalletPopupProps = Omit<ModalProps, 'children'>;

const WalletPopup = (props: WalletPopupProps) => {
  const translate = useTranslate();
  const [address, disconnect] = useConnectedAccount();
  const { formattedBalance, honeyBalance } = useAssets();

  const handleDisconnect = () => {
    disconnect();
  };

  return (
    <SimplePopup size="sm" title={translate('walletPopup:title')} {...props}>
      <CopyInput value={address || ''} mt="6" mb="6" />

      <VStack spacing="2px" mb="6">
        <WalletAsset
          py="4"
          icon={EthIcon}
          value={formattedBalance ? formattedBalance.substring(0, 6) : '–'}
        >
          <Box pr="3">
            <TinyHeading>ETH</TinyHeading>
          </Box>
        </WalletAsset>

        <WalletAsset
          py="4"
          icon={HoneyIcon}
          value={<EtherAmount amount={honeyBalance} />}
        >
          <Box pr="3">
            <TinyHeading>$HONEY</TinyHeading>
          </Box>
        </WalletAsset>
      </VStack>

      <Button
        onClick={handleDisconnect}
        variant="solid"
        colorScheme="dark"
        w="full"
      >
        {translate('common:disconnect')}
      </Button>
    </SimplePopup>
  );
};

export default WalletPopup;
