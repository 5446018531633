import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import AssetsContext from 'contexts/AssetsContext';
import { BigNumber } from 'ethers';
import ReleaseHoneyList from 'components/ReleaseHoneyList';
import ScrollableArea from 'components/ScrollableArea';
import VestingContext from 'contexts/VestingContext';
import { VestingEntity } from 'hooks/useVestingEntities';
import { formatEtherBalance } from 'utils/numberUtils';
import useClaim from 'hooks/useClaim';
import useSnackbar from 'hooks/useSnackbar';
import useTranslate from 'hooks/useTranslate';

interface ReleaseHoneyContainerProps {
  onClose: () => void;
}

const mapVestingEntities = (entities: VestingEntity[]) =>
  entities
    .filter(({ releasable }) => releasable.gt('0'))
    .map(({ tokenId }) => tokenId.toNumber());

const ReleaseHoneyContainer: React.FC<ReleaseHoneyContainerProps> = ({
  onClose,
}) => {
  const translate = useTranslate();
  const snackbar = useSnackbar();
  const { releasableBearEntities, releasableJarEntities, fetchVesting } =
    useContext(VestingContext);
  const { readHoneyBalance } = useContext(AssetsContext);

  const [selectedBearsIds, setSelectedBearsIds] = useState<number[]>(
    releasableBearEntities?.length
      ? mapVestingEntities([releasableBearEntities[0]])
      : []
  );

  const [selectedJarsIds, setSelectedJarsIds] = useState<number[]>(
    releasableJarEntities?.length
      ? mapVestingEntities([releasableJarEntities[0]])
      : []
  );

  const releasable = useMemo(() => {
    if (releasableBearEntities && releasableJarEntities) {
      const selectedBears = releasableBearEntities.filter(bear =>
        selectedBearsIds.includes(bear.tokenId.toNumber())
      );
      const selectedJars = releasableJarEntities.filter(jar =>
        selectedJarsIds.includes(jar.tokenId.toNumber())
      );

      return selectedBears
        .reduce((total, bear) => total.add(bear.releasable), BigNumber.from(0))
        .add(
          selectedJars.reduce(
            (total, jar) => total.add(jar.releasable),
            BigNumber.from(0)
          )
        );
    }
  }, [
    selectedBearsIds,
    releasableBearEntities,
    selectedJarsIds,
    releasableJarEntities,
  ]);

  const handleClaimed = useCallback(() => {
    snackbar(
      'success',
      translate('vesting:release:list:success', {
        honey: releasable ? formatEtherBalance(releasable) : '–',
      })
    );

    readHoneyBalance?.();
    fetchVesting();
    onClose();
  }, [
    snackbar,
    translate,
    releasable,
    readHoneyBalance,
    fetchVesting,
    onClose,
  ]);

  const [claim, isClaiming] = useClaim(
    selectedBearsIds,
    selectedJarsIds,
    handleClaimed
  );

  const handleSelectAll = useCallback(() => {
    if (releasableBearEntities) {
      setSelectedBearsIds(mapVestingEntities(releasableBearEntities));
    }
    if (releasableJarEntities) {
      setSelectedJarsIds(mapVestingEntities(releasableJarEntities));
    }
  }, [releasableBearEntities, releasableJarEntities]);

  const handleUnselectAll = useCallback(() => {
    setSelectedBearsIds([]);
    setSelectedJarsIds([]);
  }, []);

  const handleClaim = useCallback(() => {
    claim();
  }, [claim]);

  return (
    <VStack mt="6" spacing="6" align="stretch">
      <ScrollableArea maxHeight="calc(86vh - 200px)">
        <Box px="6">
          {releasableBearEntities && releasableBearEntities.length > 0 && (
            <ReleaseHoneyList
              title={translate('vesting:release:list:bears')}
              items={releasableBearEntities.map(({ tokenId, releasable }) => ({
                id: tokenId.toNumber(),
                honey: releasable,
              }))}
              itemType="bear"
              selectedIds={selectedBearsIds}
              onChange={setSelectedBearsIds}
            />
          )}
          {releasableJarEntities && releasableJarEntities.length > 0 && (
            <ReleaseHoneyList
              title={translate('vesting:release:list:jars')}
              items={releasableJarEntities.map(({ tokenId, releasable }) => ({
                id: tokenId.toNumber(),
                honey: releasable,
              }))}
              itemType="jar"
              selectedIds={selectedJarsIds}
              onChange={setSelectedJarsIds}
            />
          )}
        </Box>
      </ScrollableArea>

      <VStack spacing="2" align="stretch" px="6">
        <HStack spacing="2">
          <Button
            colorScheme="dark"
            size="sm"
            isFullWidth
            onClick={handleSelectAll}
          >
            {translate('common:selectAll')}
          </Button>
          <Button
            colorScheme="dark"
            size="sm"
            isFullWidth
            onClick={handleUnselectAll}
          >
            {translate('common:unselectAll')}
          </Button>
        </HStack>

        <Button
          onClick={handleClaim}
          colorScheme="secondary"
          isDisabled={!releasable || releasable.isZero()}
          isLoading={isClaiming}
        >
          {translate('vesting:release:list:release', {
            honey: releasable ? formatEtherBalance(releasable) : '–',
          })}
        </Button>
      </VStack>
    </VStack>
  );
};

export default ReleaseHoneyContainer;
