import React from 'react';
import { VStack } from '@chakra-ui/react';
import ReleaseHoneyCard from 'components/ReleaseHoneyCard';
import { BigNumber } from 'ethers';
import TinyHeading from 'components/TinyHeading';

export interface ReleaseHoneyListProps {
  title: React.ReactNode;
  items: { id: number; honey: BigNumber }[];
  itemType: 'bear' | 'jar';
  selectedIds: number[];
  onChange: (selectedIds: number[]) => void;
}

const ReleaseHoneyList: React.FC<ReleaseHoneyListProps> = ({
  title,
  items,
  itemType,
  selectedIds,
  onChange,
}) => {
  return (
    <VStack spacing="3" pt="3" align="stretch">
      <TinyHeading textAlign="left">{title}</TinyHeading>

      <VStack spacing="2px" align="stretch">
        {items.map(({ id, honey }) => {
          const isSelected = selectedIds.includes(id);

          return (
            <ReleaseHoneyCard
              key={id}
              id={id}
              honey={honey}
              type={itemType}
              onClick={() =>
                onChange(
                  isSelected
                    ? selectedIds.filter(bearId => bearId !== id)
                    : [...selectedIds, id]
                )
              }
              isSelected={isSelected}
              isDisabled={honey.isZero()}
            />
          );
        })}
      </VStack>
    </VStack>
  );
};

export default ReleaseHoneyList;
