import React from 'react';
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  HStack,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  Text,
} from '@chakra-ui/react';
import FanadiseLogo from 'components/FanadiseLogo';
import useTranslate from 'hooks/useTranslate';
import OpenseaIcon from 'components/OpenseaIcon';
import DiscordIcon from 'components/DiscordIcon';
import TwitterIcon from 'components/TwitterIcon';
import { honeyVestingContract, learnMoreUrl, traitSwapAppUrl } from 'config';

const Footer: React.FC = () => {
  const translate = useTranslate();

  return (
    <Box as="footer" mt="auto" bg="dark.900" py={4}>
      <Container>
        <HStack justify="space-between">
          <HStack spacing="5">
            <Link
              href="//opensea.io/collection/fancybearsmetaverseofficial"
              target="_blank"
              display="flex"
              alignItems="center"
            >
              <Icon as={OpenseaIcon} w="7" h="auto" />
            </Link>

            <Link
              href="//discord.gg/fancybearsmetaverse"
              target="_blank"
              display="flex"
              alignItems="center"
            >
              <Icon as={DiscordIcon} w="7" h="auto" />
            </Link>

            <Link
              href="//twitter.com/FancyBearsMeta"
              target="_blank"
              display="flex"
              alignItems="center"
            >
              <Icon as={TwitterIcon} w="7" h="auto" />
            </Link>
          </HStack>

          <HStack justify="space-between" spacing="4">
            <Link href={learnMoreUrl} target="_blank">
              <Button as="span" variant="link" fontSize="sm">
                {translate('footer:learnMore')}
              </Button>
            </Link>
            <Link href={traitSwapAppUrl} target="_blank">
              <Button as="span" variant="link" fontSize="sm">
                {translate('footer:traitSwapApp')}
              </Button>
            </Link>
            <Link
              href={`https://etherscan.io/address/${honeyVestingContract.address}`}
              target="_blank"
            >
              <Button as="span" variant="link" fontSize="sm">
                {translate('footer:viewContract')}
              </Button>
            </Link>
          </HStack>
        </HStack>

        <Divider my="2.5" />

        <LinkBox as={Center}>
          <Text fontSize="sm" fontWeight="semibold">
            {translate('footer:powered')}
          </Text>
          <LinkOverlay href="//fanadise.com" target="_blank">
            <FanadiseLogo width="115" />
          </LinkOverlay>
        </LinkBox>
      </Container>
    </Box>
  );
};

export default Footer;
