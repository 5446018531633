import en from './locales/en.json';
import fancyBearsNftAbi from './abis/fancyBears.abi.json';
import honeyJarsNftAbi from './abis/honeyJars.abi.json';
import honeyVestingNftAbi from './abis/honeyVesting.abi.json';
import { chain, erc20ABI } from 'wagmi';
import { Contract, ContractInterface } from 'ethers';

export { default as theme } from './theme';

export const environment = process.env.NODE_ENV;

export const metadataUrl = process.env.REACT_APP_METADATA_URL;
export const filestoreUrl = process.env.REACT_APP_FILESTORE_URL;
export const publicUrl = process.env.PUBLIC_URL;
export const uniswapUrl = process.env.REACT_APP_UNISWAP_URL;

export const chainName = process.env.REACT_APP_CHAIN_NAME;
export const infuraId = process.env.REACT_APP_INFURA_ID;
export const quicknodeRpcUrl = process.env.REACT_APP_QUICKNODE_RPC_URL;

export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

// @ts-expect-error
export const chains = [chain[process.env.REACT_APP_CHAIN_NAME!]];

export const chainsMulticallAddresses = {
  // @ts-expect-error
  [chain[process.env.REACT_APP_CHAIN_NAME!].id]:
    process.env.REACT_APP_CHAIN_MULTICALL_ADDRESS,
};

export const multicall2Interface: ContractInterface = [
  'function tryAggregate(bool requireSuccess, tuple(address target, bytes callData)[] calls) public view returns (tuple(bool requireSuccess, bytes returnData)[])',
];

export const fancyBearsContract = new Contract(
  process.env.REACT_APP_FANCY_BEARS_ADDRESS!,
  fancyBearsNftAbi
);

export const honeyJarsContract = new Contract(
  process.env.REACT_APP_HONEY_JARS_ADDRESS!,
  honeyJarsNftAbi
);

export const honeyTokenContract = new Contract(
  process.env.REACT_APP_HONEY_TOKEN_ADDRESS!,
  erc20ABI
);

export const honeyVestingContract = new Contract(
  process.env.REACT_APP_HONEY_VESTING_ADDRESS!,
  honeyVestingNftAbi
);

export const locales = {
  en,
};

export const learnMoreUrl = process.env.REACT_APP_LEARN_MORE_URL;
export const traitSwapAppUrl = process.env.REACT_APP_TRAIT_SWAP_APP_URL;

export const cliffStartBlock = parseInt(
  process.env.REACT_APP_HONEY_VESTING_CLIFF_START_BLOCK!
);
