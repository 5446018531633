import React, { useContext } from 'react';
import useTranslate from 'hooks/useTranslate';
import { Box, Button, ModalProps, VStack } from '@chakra-ui/react';
import VestingContext from 'contexts/VestingContext';
import EmptyState from 'components/EmptyState';
import TokensDetailsList from 'components/VestingDetailsList';
import ScrollableArea from 'components/ScrollableArea';
import SimplePopup from 'components/SimplePopup';

type VesitngDetailsPopupProps = Omit<ModalProps, 'children'> & {};

const VesitngDetailsPopup: React.FC<VesitngDetailsPopupProps> = ({
  onClose,
  ...rest
}) => {
  const translate = useTranslate();
  const { bearEntities, jarEntities } = useContext(VestingContext);

  return (
    <SimplePopup
      title={translate('vesting:details:title')}
      onClose={onClose}
      {...rest}
    >
      <ScrollableArea mt="6" maxHeight="calc(86vh - 40px)">
        <VStack spacing="3" align="stretch">
          {bearEntities && jarEntities && (
            <>
              {bearEntities.length === 0 && jarEntities.length === 0 ? (
                <EmptyState mt="8" mb="4" opacity="0.8" />
              ) : (
                <>
                  {bearEntities.length > 0 && (
                    <TokensDetailsList
                      type="bear"
                      entities={bearEntities}
                      defaultIndex={0}
                    />
                  )}
                  {jarEntities.length > 0 && (
                    <TokensDetailsList
                      type="jar"
                      defaultIndex={bearEntities.length < 1 ? 0 : undefined}
                      entities={jarEntities}
                    />
                  )}
                </>
              )}
            </>
          )}
        </VStack>
      </ScrollableArea>

      <Box pt="2">
        <Button onClick={() => onClose()} colorScheme="dark" isFullWidth>
          {translate('common:close')}
        </Button>
      </Box>
    </SimplePopup>
  );
};

export default VesitngDetailsPopup;
