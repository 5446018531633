/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useMemo } from 'react';
import AssetsContext, { AssetsContextValue } from 'contexts/AssetsContext';
import useFancyBearsInWallet from 'hooks/useFancyBearsInWallet';
import useHoneyJarsInWallet from 'hooks/useHoneyJarsInWallet';
import useConnectedAccount from 'hooks/useConnectedAccount';
import useFormattedBalance from 'hooks/useFormattedBalance';
import useHoneyBalance from 'hooks/useHoneyBalance';

const AssetsProvider: FunctionComponent = ({ children }) => {
  const [address] = useConnectedAccount();
  const [
    fancyBearsInWallet,
    readFancyBearsInWallet,
    readFancyBearsClaimStatus,
  ] = useFancyBearsInWallet(address);
  const [honeyBalance, readHoneyBalance] = useHoneyBalance(address);

  const [honeyJarsInWallet, readHoneyJarsInWallet] =
    useHoneyJarsInWallet(address);

  const formattedBalance = useFormattedBalance(address);

  const contextValue = useMemo<AssetsContextValue>(
    () => ({
      formattedBalance,
      fancyBearsInWallet,
      fancyBearsInWalletCount: fancyBearsInWallet?.length,
      honeyJarsInWallet,
      honeyJarsInWalletCount: honeyJarsInWallet?.length,
      honeyBalance,

      readHoneyBalance,
      readFancyBearsInWallet,
      readFancyBearsClaimStatus,
      readHoneyJarsInWallet,
    }),
    [
      formattedBalance,
      fancyBearsInWallet,
      honeyJarsInWallet,
      honeyBalance,
      readHoneyBalance,
    ]
  );

  return (
    <AssetsContext.Provider value={contextValue}>
      {children}
    </AssetsContext.Provider>
  );
};

export default AssetsProvider;
