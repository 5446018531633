import { useContractWrite, useWaitForTransaction } from 'wagmi';
import { useEffect, useMemo } from 'react';

import { honeyVestingContract } from 'config';
import useCaptureRpcException from 'hooks/useCaptureRpcException';
import useSnackbarRpcError from 'hooks/useSnackbarRpcError';

const useClaim = (
  fancyBearIds: number[],
  honeyJarsIds: number[],
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const args = useMemo(
    () => [fancyBearIds, honeyJarsIds],
    [fancyBearIds, honeyJarsIds]
  );

  const [{ data, loading: isLoading, error: writeError }, claim] =
    useContractWrite(
      {
        addressOrName: honeyVestingContract.address,
        contractInterface: honeyVestingContract.interface,
      },
      'claimAll',
      {
        args,
      }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({
      wait: data?.wait,
    });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);

  useCaptureRpcException(waitError);
  useCaptureRpcException(writeError);

  console.log('claim [args]', args);

  console.log('claim [write]', writeError);
  console.log('claim [wait]', waitError);

  useEffect(() => {
    if (!isWaiting && waitData && onSuccess) {
      onSuccess();
    }
  }, [isWaiting, waitData, onSuccess]);

  return useMemo(
    () => [claim, isLoading || isWaiting],
    [claim, isWaiting, isLoading]
  );
};

export default useClaim;
