import { useCallback, useEffect, useMemo, useState } from 'react';

import { BigNumber } from 'ethers';
import { honeyVestingContract } from 'config';
import { useContractRead } from 'wagmi';
import useMulticall from 'hooks/useMulticall';

export interface VestingEntity {
  remaining: BigNumber;
  releasable: BigNumber;
  released: BigNumber;
  spent: BigNumber;
  tokenId: BigNumber;
}

const useVestingEntities = (
  remainingsMethod: string,
  statsMethod: string,
  address?: string
): [
  entities: VestingEntity[] | undefined,
  remainingsTotal: BigNumber | undefined,
  releasablesTotal: BigNumber | undefined,
  releasedTotal: BigNumber | undefined,
  fetchEntities: () => void
] => {
  const multicall = useMulticall();
  const [entities, setEntities] = useState<VestingEntity[]>();
  const remainingsTotal = useMemo(
    () =>
      entities?.reduce(
        (sum, { remaining }) => sum.add(remaining),
        BigNumber.from(0)
      ),
    [entities]
  );
  const releasablesTotal = useMemo(
    () =>
      entities?.reduce(
        (sum, { releasable }) => sum.add(releasable),
        BigNumber.from(0)
      ),
    [entities]
  );
  const releasedTotal = useMemo(
    () =>
      entities?.reduce(
        (sum, { released }) => sum.add(released),
        BigNumber.from(0)
      ),
    [entities]
  );

  const [{ data: remainingsData }] = useContractRead(
    {
      addressOrName: honeyVestingContract.address,
      contractInterface: honeyVestingContract.interface,
    },
    remainingsMethod,
    {
      args: address,
    }
  );

  const fetchEntities = useCallback(async () => {
    if (!remainingsData) {
      return;
    }

    try {
      const [, tokenIds] = remainingsData as [BigNumber[], BigNumber[]];

      const entities = (
        await multicall(
          tokenIds.map(tokenId => ({
            address: honeyVestingContract.address,
            data: honeyVestingContract.interface.encodeFunctionData(
              statsMethod,
              [tokenId, Math.floor(Date.now() / 1000)]
            ),
          }))
        )
      ).map(
        ([, data]) =>
          honeyVestingContract.interface.decodeFunctionResult(
            statsMethod,
            data
          ) as unknown as Omit<VestingEntity, 'tokenId'>
      );

      setEntities(
        tokenIds.map((tokenId, i) => ({
          tokenId,
          ...entities[i],
        }))
      );
    } catch (err) {
      console.error(err);
    }
  }, [remainingsData, statsMethod, multicall]);

  useEffect(() => {
    fetchEntities();
  }, [fetchEntities]);

  return [
    entities,
    remainingsTotal,
    releasablesTotal,
    releasedTotal,
    fetchEntities,
  ];
};

export default useVestingEntities;
