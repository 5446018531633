import { chainsMulticallAddresses } from 'config';
import { useNetwork } from 'wagmi';

const useMulticallAddress = () => {
  const [
    {
      data: { chain },
    },
  ] = useNetwork();
  return chain && chainsMulticallAddresses[chain.id]!;
};

export default useMulticallAddress;
