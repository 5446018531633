import { PropsWithChildren } from 'react';
import { SimpleGrid, SimpleGridProps, Text, TextProps } from '@chakra-ui/react';

export type DataListProps = PropsWithChildren<SimpleGridProps>;

const DataList = (props: DataListProps) => (
  <SimpleGrid as="dl" columns={2} gap={2} w="full" {...props} />
);

DataList.Term = (props: PropsWithChildren<TextProps>) => (
  <Text
    as="dt"
    textAlign="left"
    lineHeight="5"
    color="bright.400"
    fontWeight="normal"
    {...props}
  />
);

DataList.Value = (props: PropsWithChildren<TextProps>) => (
  <Text
    as="dd"
    textAlign="right"
    lineHeight="5"
    fontWeight="semibold"
    {...props}
  />
);

export default DataList;
