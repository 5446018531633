import React from 'react';
import useTranslate from 'hooks/useTranslate';
import { Accordion, VStack, AccordionProps } from '@chakra-ui/react';
import TinyHeading from 'components/TinyHeading';
import VestingDetailsCard from 'components/VestingDetailsCard';
import { VestingEntity } from 'hooks/useVestingEntities';

type TokensDetailsListProps = AccordionProps & {
  type: 'bear' | 'jar';
  entities: VestingEntity[];
};

const TokensDetailsList: React.FC<TokensDetailsListProps> = ({
  type,
  entities,
  ...rest
}) => {
  const translate = useTranslate();

  return (
    <Accordion reduceMotion={true} allowToggle {...rest}>
      <TinyHeading textAlign="left" mb="3">
        {translate(`vesting:details:list:${type}`)}
      </TinyHeading>

      <VStack spacing="2px" align="stretch">
        {entities.map(entity => (
          <VestingDetailsCard
            key={entity.tokenId.toString()}
            type={type}
            stats={entity}
          />
        ))}
      </VStack>
    </Accordion>
  );
};

export default TokensDetailsList;
