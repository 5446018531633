import React from 'react';
import { BigNumber } from 'ethers';

export type AssetsContextValue = {
  formattedBalance?: string;
  fancyBearsInWallet?: [tokenId: number, isClaimed: boolean][];
  fancyBearsInWalletCount?: number;
  readFancyBearsInWallet: () => void;
  readFancyBearsClaimStatus: () => void;
  honeyJarsInWallet?: number[];
  honeyJarsInWalletCount?: number;
  readHoneyJarsInWallet: () => void;
  honeyBalance?: BigNumber;
  readHoneyBalance: () => void;
};

const AssetsContext = React.createContext<AssetsContextValue>({
  formattedBalance: undefined,
  fancyBearsInWallet: undefined,
  fancyBearsInWalletCount: undefined,
  readFancyBearsInWallet: () => {},
  readFancyBearsClaimStatus: () => {},
  honeyJarsInWallet: undefined,
  honeyJarsInWalletCount: undefined,
  readHoneyJarsInWallet: () => {},
  honeyBalance: undefined,
  readHoneyBalance: () => {},
});

export default AssetsContext;
