import { Flex, FlexProps, Text, Icon } from '@chakra-ui/react';
import EtherAmount from 'components/EtherAmount';
import HoneyIcon from 'components/HoneyIcon';
import { BigNumberish } from 'ethers';

type HoneyAmountProps = FlexProps & {
  amount: BigNumberish;
};

const HoneyAmount = ({ amount, ...rest }: HoneyAmountProps) => (
  <Flex align="center" gap="1" flex="none" {...rest}>
    <Icon as={HoneyIcon} />
    <Text>
      <EtherAmount amount={amount} />
    </Text>
  </Flex>
);

export default HoneyAmount;
