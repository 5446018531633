import { honeyTokenContract } from 'config';
import { useContractRead } from 'wagmi';
import { BigNumber } from 'ethers';

const useHoneyBalance = (
  address?: string
): [honeyBalance: BigNumber | undefined, readHoneyBalance: () => void] => {
  const [{ data: honeyJarsInWallet }, readHoneyBalance] = useContractRead(
    {
      addressOrName: honeyTokenContract.address,
      contractInterface: honeyTokenContract.interface,
    },
    'balanceOf',
    {
      args: address,
    }
  );

  return [honeyJarsInWallet as BigNumber | undefined, readHoneyBalance];
};

export default useHoneyBalance;
