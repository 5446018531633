import { Center, Container, Heading, Spinner, VStack } from '@chakra-ui/react';

import AssetsProvider from 'providers/AssetsProvider';
import Header from 'components/Header';
import Panel from 'components/Panel';
import React from 'react';
import VestingBalance from 'components/VestingBalance';
import VestingChart from 'components/VestingChart';
import VestingContext from 'contexts/VestingContext';
import VestingProvider from 'providers/VestingProvider';
import useTranslate from 'hooks/useTranslate';

const HomePage: React.FC = () => {
  const translate = useTranslate();

  return (
    <AssetsProvider>
      <VestingProvider>
        <Header />

        <Container>
          <VestingContext.Consumer>
            {({ data, hasVestingEnded }) =>
              data ? (
                <VStack w="full" align="stretch" pt="12" pb="16" spacing="3">
                  <Panel p="3">
                    <Heading
                      fontSize="2xl"
                      fontWeight="bold"
                      px="3"
                      pb="4"
                      pt="1.5"
                    >
                      {translate('home:vesting:title')}
                    </Heading>

                    <VestingBalance />
                  </Panel>

                  <Panel p="3">
                    <Heading
                      fontSize="xl"
                      fontWeight="bold"
                      px="3"
                      pb="4"
                      pt="1.5"
                    >
                      {translate('home:timeline:title')}
                    </Heading>

                    <VestingChart />
                  </Panel>
                </VStack>
              ) : (
                <Center height="34rem">
                  <Spinner
                    size="xl"
                    color="primary.500"
                    thickness="4px"
                    my="20"
                    mx="auto"
                    display="block"
                  />
                </Center>
              )
            }
          </VestingContext.Consumer>
        </Container>
      </VestingProvider>
    </AssetsProvider>
  );
};

export default HomePage;
