import { useEffect, useMemo, useState } from 'react';
import { fancyBearsContract, honeyJarsContract } from 'config';
import { useContractRead } from 'wagmi';

const useFancyBearsInWallet = (
  address?: string
): [
  tokenIds: [tokenId: number, isClaimed: boolean][] | undefined,
  readFancyBearsInWallet: () => void,
  readFancyBearsClaimStatus: () => void
] => {
  const [fancyBearsInWallet, setFancyBearsInWallet] =
    useState<[tokenId: number, isClaimed: boolean][]>();

  const [{ data: fancyBearsInWalletData }, readFancyBearsInWallet] =
    useContractRead(
      {
        addressOrName: fancyBearsContract.address,
        contractInterface: fancyBearsContract.interface,
      },
      'tokensInWallet',
      {
        args: address,
      }
    );

  const [{ data: fancyBearsClaimStatusData }, readFancyBearsClaimStatus] =
    useContractRead(
      {
        addressOrName: honeyJarsContract.address,
        contractInterface: honeyJarsContract.interface,
      },
      'fancyBearsClaimStatusByAddress',
      {
        args: address,
      }
    );

  useEffect(() => {
    if (fancyBearsInWalletData && fancyBearsClaimStatusData) {
      setFancyBearsInWallet(
        fancyBearsInWalletData?.map((bn, index) => [
          bn.toNumber() as number,
          !!fancyBearsClaimStatusData?.[index],
        ])
      );
    }
  }, [fancyBearsInWalletData, fancyBearsClaimStatusData]);

  return useMemo(
    () => [
      fancyBearsInWallet,
      readFancyBearsInWallet,
      readFancyBearsClaimStatus,
    ],
    [fancyBearsInWallet, readFancyBearsInWallet, readFancyBearsClaimStatus]
  );
};

export default useFancyBearsInWallet;
