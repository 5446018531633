import { Box, BoxProps, Flex, useTheme } from '@chakra-ui/react';
import { hexToRGB } from 'utils/colorUtils';

type ScrollableAreaProps = BoxProps;

const ScrollableArea = ({ children, ...rest }: ScrollableAreaProps) => {
  const { colors } = useTheme();

  return (
    <Flex
      direction="column"
      overflowY="auto"
      align="stretch"
      w="full"
      maxH="full"
      h="full"
      position="relative"
      {...rest}
    >
      <Box overflowY="auto" pb="4">
        {children}
      </Box>

      <Box
        position="absolute"
        bottom="0"
        h="8"
        left="0"
        right="0"
        pointerEvents="none"
        zIndex="base"
        bg={`linear-gradient(180deg, ${hexToRGB(
          colors.dark[900],
          0
        )} 0%, ${hexToRGB(colors.dark[900], 1)} 100%)`}
      />
    </Flex>
  );
};

export default ScrollableArea;
