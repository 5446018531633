/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

const useCaptureException = (error?: Error) => {
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);
};

export default useCaptureException;
