import Logo from 'components/Logo';
import Panel from 'components/Panel';
import React from 'react';
import useSnackbarRpcError from 'hooks/useSnackbarRpcError';
import useTranslate from 'hooks/useTranslate';
import { publicUrl } from 'config';
import { useConnect } from 'wagmi';

import {
  Button,
  Center,
  Box,
  VStack,
  Text,
  Heading,
  Container,
  Image,
  Icon,
} from '@chakra-ui/react';
import MetaMaskIcon from 'components/MetaMaskIcon';
import WalletConnectIcon from 'components/WalletConnectIcon';
import CoinbaseWalletIcon from 'components/CoinbaseWalletIcon';

const connectorIcons: Record<string, React.FC> = {
  MetaMask: MetaMaskIcon,
  WalletConnect: WalletConnectIcon,
  'Coinbase Wallet': CoinbaseWalletIcon,
};

const ConnectPage: React.FC = () => {
  const translate = useTranslate();

  const [{ data: connectData, error }, connect] = useConnect();
  useSnackbarRpcError(error);

  return (
    <Container
      display="flex"
      pt="12"
      pb="12"
      maxW="md"
      flex="1"
      alignItems="center"
    >
      <VStack w="full" align="center" spacing="6">
        <Box pb="3">
          <Box as={Logo} w="40" h="auto" flexShrink="0" color="dark.800" />
        </Box>

        <Center flexDir="column">
          <Image
            src={`${publicUrl}/connect-illustration.png`}
            w="full"
            maxW="260px"
          />
        </Center>

        <VStack textAlign="center" mb="6" mt="1" color="dark.900">
          <Heading fontSize="3xl" w="full" textAlign="center">
            {translate('connect:appTitle')}
          </Heading>

          <Text opacity="0.8" fontWeight="semibold">
            {translate('connect:appDescription')}
          </Text>
        </VStack>

        <Panel p="8" bg="dark.800">
          <Heading fontSize="xl" w="full" textAlign="center" mb="6" mt="1">
            {translate('connect:title')}
          </Heading>

          <VStack w="full" align="stretch" spacing="4" flexShrink="0">
            {connectData.connectors.map(connector => {
              return (
                <Button
                  key={connector.id}
                  onClick={() => connect(connector)}
                  isDisabled={!connector.ready}
                  size="lg"
                  w="full"
                  iconSpacing="3"
                  variant="outline"
                  colorScheme="primary"
                  borderRadius="full"
                  leftIcon={
                    <Icon as={connectorIcons[connector.name]} w="8" h="8" />
                  }
                >
                  {translate('connect:button:label', {
                    connector: connector.name,
                  })}
                </Button>
              );
            })}
          </VStack>
        </Panel>
      </VStack>
    </Container>
  );
};

export default ConnectPage;
