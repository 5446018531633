import React from 'react';
import { BigNumber } from 'ethers';
import { VestingEntity } from 'hooks/useVestingEntities';

export type VestingContextValue = {
  data?: {
    cliffStart: BigNumber;
    cliffEnd: BigNumber;
    rewardPeriod: BigNumber;
    numberOfRewardPeriods: BigNumber;
  };
  hasVestingEnded?: boolean;
  bearEntities?: VestingEntity[];
  releasableBearEntities?: VestingEntity[];
  jarEntities?: VestingEntity[];
  releasableJarEntities?: VestingEntity[];
  remainingsTotal?: BigNumber;
  releasedTotal?: BigNumber;
  releasableTotal?: BigNumber;
  scheduleForBear?: BigNumber;
  scheduleForJar?: BigNumber;
  fetchVesting: () => void;
};

const VestingContext = React.createContext<VestingContextValue>({
  fetchVesting: () => {},
});

export default VestingContext;
