import { useCallback } from 'react';
import { useProvider } from 'wagmi';
import useMulticallAddress from 'hooks/useMulticallAddress';
import { Contract } from 'ethers';
import { multicall2Interface } from 'config';

const useMulticall = () => {
  const provider = useProvider();
  const multicallAddress = useMulticallAddress();

  return useCallback(
    async (
      calls: {
        address: string;
        data: string;
      }[]
    ) => {
      const contract = new Contract(
        multicallAddress!,
        multicall2Interface,
        provider
      );
      const results: [boolean, string][] = await contract.tryAggregate(
        false,
        calls.map(({ address, data }) => [address, data])
      );

      return results;
    },
    [provider, multicallAddress]
  );
};

export default useMulticall;
