import { useEffect, useState } from 'react';
import { honeyVestingContract } from 'config';
import { useContractRead } from 'wagmi';
import { BigNumber } from 'ethers';

const useVestingSchedule = (
  method: string
): [BigNumber | undefined, () => void] => {
  const [scheduleAmount, setScheduleAmount] = useState<BigNumber>();

  const [{ data: scheduleAmountData }, readScheduleAmount] = useContractRead(
    {
      addressOrName: honeyVestingContract.address,
      contractInterface: honeyVestingContract.interface,
    },
    method,
    {
      args: [Math.floor(Date.now() / 1000)],
    }
  );

  useEffect(() => {
    if (scheduleAmountData !== undefined) {
      setScheduleAmount(scheduleAmountData as unknown as BigNumber);
    }
  }, [scheduleAmountData]);

  return [scheduleAmount, readScheduleAmount];
};

export default useVestingSchedule;
